/**
 * @namespace Five9
 */
define([
  'underscore',
  'sdk.public/crm/interaction.api/interaction.api.factory',
  'sdk.public/crm/custom.components.api/components.api.factory',
  'sdk.public/crm/crm.api/crm.api.factory',
  'sdk.public/crm/hook.api/hook.api.factory',
  'sdk.public/crm/custom.methods.api/custom.methods.api.factory',
  'sdk.public/crm/sf.native.api/sf.native.api.factory',
  'sdk.public/crm/application.api/application.api.factory',
  'sdk.public/types/api.error.code',
  'sdk.public/crm/hook.api/hook.api.status.code'
], function(
  _,
  interactionApi,
  customComponentsApi,
  crmApi,
  hookApi,
  customMethodsApi,
  sfNativeApi,
  applicationApi,
  ApiErrorCode,
  HookStatusCode
) {
  _.noConflict();

  /**
   * @namespace CrmSdk
   * @memberof Five9
   */
  return {
    /**
     * @function interactionApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain reference to Interaction API instance. If Five9 Plugin SDK is loaded
     *```
     * const interactionApi = window.Five9.CrmSdk.interactionApi();
     *```
     * in multiple iframes on the same page all instances will receive events and can be used to execute methods.
     * @returns {InteractionApi} reference to Interaction API instance
     */
    interactionApi,

    /**
     * @function customComponentsApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain reference to Custom Components API instance. Only one instance of Custom Components API can be used on the same page.
     * If Five9 Plugin SDK is loaded in multiple iframes on the same page the first instance of Custom Components API that establishes connection to Five9
     * Agent Desktop Toolkit will receive events
     *```
     * const customComponentsApi = window.Five9.CrmSdk.customComponentsApi();
     *```
     * @returns {CustomComponentsApi} reference to Custom Components API instance
     */
    customComponentsApi,

    /**
     * @function crmApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain reference to CRM API instance. Only one instance of CRM API can be used on the same page.
     * If Five9 Plugin SDK is loaded in multiple iframes on the same page the first instance of CRM API that establishes connection to Five9
     * Agent Desktop Toolkit will receive events
     *```
     * const crmApi = window.Five9.CrmSdk.crmApi();
     *```
     * @returns {CrmApi} reference to CRM API instance
     */
    crmApi,

    /**
     * @function hookApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain reference to Hook API instance. Only one instance of Hook API can be used on the same page.
     * If Five9 Plugin SDK is loaded in multiple iframes on the same page the first instance of Hook API that establishes connection to Five9
     * Agent Desktop Toolkit will receive events
     *```
     * const hookApi = window.Five9.CrmSdk.hookApi();
     *```
     * @returns {HookApi} reference to Hook API instance
     */
    hookApi,

    /**
     * @function customMethodsApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain a Custom Methods API instance. You can use this API to establish a communication channel between
     * a customization bundle and an iframe on a page. So you can call methods implemented in an iframe from a customization bundle and vice versa.
     *```
     * const customMethodsApi = window.Five9.CrmSdk.customMethodsApi();
     *```
     * @returns {CustomMethodsApi} reference to Custom Methods API instance
     */
    customMethodsApi,

    /**
     * @function sfNativeApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain a Salesforce Native API instance. You can use this API to establish a communication channel between
     * a customization bundle and an iframe on a page. So you can call methods implemented in an iframe from a customization bundle and vice versa.
     *```
     * const sfNativeApi = window.Five9.CrmSdk.sfNativeApi();
     *```
     * @returns {SfNativeApi} reference to Salesforce Native API instance
     */
    sfNativeApi,

    /**
     * @function applicationApi
     * @memberof Five9.CrmSdk
     * @instance
     * @description Use this method to obtain reference to Application API instance. If Five9 Plugin SDK is loaded
     *```
     * const applicationApi = window.Five9.CrmSdk.applicationApi();
     *```
     * @returns {ApplicationApi} reference to Application API instance
     */
    applicationApi,

    /**
     * @property {ApiErrorCode} ApiErrorCode API error codes
     * @memberof Five9.CrmSdk
     */
    ApiErrorCode,

    /**
     * @property {HookApiStatusCode} HookStatusCode Hook API status codes
     * @memberof Five9.CrmSdk
     */
    HookStatusCode
  };
});
